<template>
    <!--车辆出入查询-->
    <SSafeInoutCarScrollWrapper />
  </template>
  
  <script>
  import SSafeInoutCarScrollWrapper from '@/components/scrollWrapper/SSafeInoutCar.vue'
  export default {
    name: "SSafeInoutCar",
    components: {
        SSafeInoutCarScrollWrapper
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  